
import { defineComponent, ref } from "vue";
import Navagation from "@/components/commons/Navagation.vue";
import sort from "@/assets/nfts/sort.webp";

import { useRoute } from "vue-router";
import { getItemDetail } from "@/model/nftLanding";
import { getLongStringShow } from "@/utils/formatString";
import { displayDateFormat } from "@/utils/formatTime";
import { kmbNumber, getFloat, formatPercentageValue } from "@/utils/formatNumber";
import { getQueryString } from "@/utils/url";
import { modifyImgSize } from "@/utils/imgSize";
import { useLog } from "@/utils/log";

export default defineComponent({
  name: "NftDetail",
  components: {
    Navagation,
  },
  setup() {
    const state: any = ref({});
    const route = useRoute();
    const { handleClickLog } = useLog();
    getItemDetail({
      contractAddr: route.params.addr,
      tokenId: route.params.tokenId,
    }).then((res) => {
      if (!res?.code) {
        state.value = res?.data || {};
        state.value.hadData = true;
      }
    });
    const getHref = () => {
      return state.value?.belongToCollection?.id
        ? `/collection-detail/${
            state.value?.contract
          }?${getQueryString(["contract"])}`
        : "javascript:;";
    };
    return {
      sort,
      state,
      getLongStringShow,
      kmbNumber,
      getFloat,
      formatPercentageValue,
      displayDateFormat,
      handleClickLog,
      getQueryString,
      modifyImgSize,
      getHref,
    };
  },
});
